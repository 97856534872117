import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import useStyles from '../common/styles';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import Logo from '../assets/logo.png';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import { backendURL } from '../App';
import { useState } from 'react';
import dayjs from 'dayjs';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "formTitle": "S-OIL CHAMPIONSHIP 2024\nPRO-AM Participation Form",
          "privacyTitle": "Agreement on the Collection and Usage of Personal Information",
          "privacyContent": `
            The secreteriat seeks your permission for the collection and use of your personal information for the purpose of filling out the application form and managing the event.

            Your personal information will not be used for any purpose other than those stated above. The collected information will be securely destroyed after the event concludes.

            You have the right to refuse consent; however, please note that refusal to consent may result in restrictions on your participation in the event.

            Do you agree to the collection and use of your personal information as described above?`
          ,
          'yes': 'Yes',
          'no': 'No',
          'name': 'Name(Full Name)',
          'fullName': 'Full Name',
          'gender': 'Gender',
          'male': 'Male',
          'female': 'Female',
          'birth': 'Date of Birth',
          'nationality': 'Nationality',
          'company': 'Company',
          'position': 'Position',
          'address': 'Address',
          'officeContact': 'Office Tel.',
          'mobile': 'Mobile',
          'departureAirport': 'Airport of Departure',
          'arrivalAirport': 'Airport of Arrival',
          'departureDefault': 'Gimpo → Jeju : Depart at 06:40A.M. Oct.30',
          'arrivalDefault': 'Jeju → Gimpo : Depart at 21:10P.M. Oct.30',
          'other': 'Others',
          'submit': 'Submit',
          'gloveSize': 'Glove Size',
          'leftHanded': 'Check if you are left-handed',
          'specialMeal': 'Special Meal Request',
          'none': 'None',
          'specialMealDescription': 'Input request details',
          'additional': `
            ※ If you are departing from an airport other than Gimpo. Please fill in the name of the airport.
            ※ Please fill in your address where you can receive caddie bag flight cover preparce by S-OIL.
            ※ Please complete and submit the participation form, by Friday, September 20th.
            ※ Inquiries : Secretariat (Tel : +82-2-6214-0104, Mobile : +82-10-3967-1551, Email : rkddnjs@brion.co.kr)
          `
        }
      },
      ko: {
        translation: {
          "formTitle": "S-OIL CHAMPIONSHIP 2024\n프로암 참가 신청서",
          "privacyTitle": "개인정보 수집 및 이용 동의",
          "privacyContent": `
            대회 사무국은 참가 신청서 작성 및 대회 운영을 위해 고객님의 개인정보를 수집하고자 합니다.

            수집된 개인정보는 프로암 참가 고객 관리 및 대회 운영 목적을 위해 사용됩니다. 동의하신 개인정보는 위에 명시된 수집 및 이용 목적 외로 사용되지 않습니다. 수집된 개인정보는 대회 종료 후 안전하게 파기됩니다.
            
            귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 대회 참가 신청이 제한될 수 있음을 안내드립니다.

            위의 개인정보 수집 및 이용에 동의하십니까?`
          ,
          'yes': '예',
          'no': '아니요',
          'name': '성함',
          'fullName': '영문 성함(Full Name)',
          'gender': '성별',
          'male': '남',
          'female': '여',
          'birth': '생년월일',
          'nationality': '국적',
          'company': '회사',
          'position': '직위',
          'address': '주소',
          'officeContact': '사무실 연락처',
          'mobile': '휴대폰 번호',
          'departureAirport': '출발 공항',
          'arrivalAirport': '도착 공항',
          'departureDefault': '김포발 → 제주행 10/30(수) 06:40',
          'arrivalDefault': '제주발 → 김포행 10/30(수) 21:10',
          'other': '기타',
          'submit': '정보 전송',
          'gloveSize': '장갑 사이즈',
          'leftHanded': '좌타자인 경우 체크',
          'specialMeal': '채식/알레르기 등 식사 관련 요청',
          'none': '없음',
          'specialMealDescription': '요청 사항 입력',
          'additional': `
            ※ 김포 이외의 다른 공항에서 출발하시는 경우, "기타" 항목에 체크하시고 해당 공항명을 기재해 주시면, 대회 사무국에서 별도로 연락드리겠습니다.
            ※ 캐디백 항공커버를 사전에 발송해 드릴 예정이오니 수령하실 주소를 반드시 기입하여 주시기 바랍니다.
            ※ 신청서는 9/4(수) 까지 모든 항목의 답변을 작성하신 후 제출해 주시기 바랍니다.
            ※ 문의 : 대회사무국(Tel : 02-6214-0104, Mobile : 010-3967-1551, Email : rkddnjs@brion.co.kr)
          `
        }
      }
    },
    lng: "ko", // if you're using a language detector, do not define the lng option
    fallbackLng: "ko",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default function Invitation({ participantInfo }: { participantInfo?: Record<string, any> }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isArrivalDisabled, setIsArrivalDisabled] = useState(true);
  const [isDepartureDisabled, setIsDepartureDisabled] = useState(true);
  const [isSpecialMealDisabled, setIsSpecialMealDisabled] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: "",
      nameEng: "",
      gender: "",
      birthdate: "",
      nationality: "",
      company: "",
      position: "",
      address: "",
      officePhone: "",
      mobilePhone: "",
      flightDeparture: "",
      flightArrival: "",
      gloveSize: "",
      gloveLeftHanded: false,
      specialMeal: "",
    },
    onSubmit: (form) => {
      form.birthdate = dayjs(form.birthdate).format('YYYY-MM-DD');

      if (participantInfo) {
        fetch(`${backendURL}s-oil-api/participants/${participantInfo.pHash}`, {
          method: "PUT",
          body: JSON.stringify(form),
          headers: {
            'content-type': 'application/json',
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json()
          })
          .then(response => {
            alert('Success');
          })
          .catch(error => {
            console.error('Error:', error);
            alert('Error');
          })
      }
    },
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.paper}>
        <Container maxWidth="xs">
          <img src={Logo} alt="logo" style={{ width: '100%' }} />
        </Container>
        <Stack sx={{ margin: '8px auto' }} spacing={2} direction="row">
          <Button variant={i18n.language === 'ko' ? 'contained' : 'outlined'} startIcon={<span className="fi fi-kr"></span>} onClick={() => i18n.changeLanguage('ko')}>
            한국어
          </Button>
          <Button variant={i18n.language === 'en' ? 'contained' : 'outlined'} startIcon={<span className="fi fi-us"></span>} onClick={() => i18n.changeLanguage('en')}>
            English
          </Button>
        </Stack>
        <Typography variant="h6" gutterBottom textAlign="center" sx={{ whiteSpace: 'pre-wrap' }}>
          {t("formTitle")}
        </Typography>
        <Card className={classes.form}>
          <CardContent>
            <Typography variant="h6">
              {t("privacyTitle")}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>
              {t("privacyContent").replace(/^ +/gm, '')}
            </Typography>
            <RadioGroup row>
              <FormControlLabel control={<Radio />} label={t('yes')} value="Y" />
              <FormControlLabel control={<Radio />} label={t('no')} value="N" />
            </RadioGroup>
          </CardContent>
        </Card>
        <Card className={classes.form}>
          <CardContent>
            <List>
              <InputLabel>{t('name')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined" name="name" onChange={formik.handleChange} value={formik.values.name} />
              </ListItem>
              {
                i18n.language === 'ko' && (<>
                  <InputLabel>영문 성함(Full Name)</InputLabel>
                  <ListItem>
                    <TextField fullWidth variant="outlined" name="nameEng" onChange={formik.handleChange} value={formik.values.nameEng} />
                  </ListItem>
                </>
                )
              }
              <InputLabel>{t('gender')}</InputLabel>
              <ListItem>
                <RadioGroup row onChange={formik.handleChange} value={formik.values.gender} name="gender">
                  <FormControlLabel control={<Radio />} label={t('male')} value="M" />
                  <FormControlLabel control={<Radio />} label={t('female')} value="F" />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('birth')}</InputLabel>
              <ListItem>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <DatePicker sx={{ width: '100%' }} name="birthdate" value={formik.values.birthdate} onChange={(value) => formik.setFieldValue("birthdate", value, true)} />
                </LocalizationProvider>
              </ListItem>
              <InputLabel>{t('nationality')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined" name="nationality" value={formik.values.nationality} onChange={formik.handleChange} />
              </ListItem>
              <InputLabel>{t('company')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="company" value={formik.values.company} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('position')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="position" value={formik.values.position} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('address')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined" multiline
                  name="address" value={formik.values.address} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('officeContact')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="officePhone" value={formik.values.officePhone} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('mobile')}</InputLabel>
              <ListItem>
                <TextField fullWidth variant="outlined"
                  name="mobilePhone" value={formik.values.mobilePhone} onChange={formik.handleChange}
                />
              </ListItem>
              <InputLabel>{t('departureAirport')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel control={<Radio />} label={<Typography variant="body2" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { fontSize: i18n.language === 'en' ? '0.6rem' : undefined } })}>{t('departureDefault')}</Typography>} onClick={() => setIsDepartureDisabled(true)}
                    value="departureDefault"
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('other')} size="small"
                      name="flightDeparture" value={formik.values.flightDeparture} onChange={formik.handleChange}
                      disabled={isDepartureDisabled}
                    />
                  }
                    value="departureOther"
                    onClick={() => setIsDepartureDisabled(false)} />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('arrivalAirport')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel
                    control={<Radio />} label={<Typography variant="body2" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { fontSize: i18n.language === 'en' ? '0.6rem' : undefined } })}>{t('arrivalDefault')}</Typography>} onClick={() => setIsArrivalDisabled(true)}
                    value="arrivalDefault"
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('other')} size="small"
                      name="flightArrival" value={formik.values.flightArrival} onChange={formik.handleChange}
                      disabled={isArrivalDisabled}
                    />
                  }
                    value="arrivalOther"
                    onClick={() => setIsArrivalDisabled(false)} />
                </RadioGroup>
              </ListItem>
              <InputLabel>{t('gloveSize')}</InputLabel>
              <ListItem>
                <FormGroup>
                  <Select size="small"
                    name="gloveSize" value={formik.values.gloveSize} onChange={formik.handleChange}
                  >
                    {Array.from({ length: 9 }, (_, i) => i + 18).map((size) => (
                      <MenuItem key={size} value={size}>{size}</MenuItem>
                    ))}
                  </Select>
                  <FormControlLabel control={<Checkbox
                    checked={formik.values.gloveLeftHanded}
                    onChange={formik.handleChange}
                    name="gloveLeftHanded"
                  />} label={t('leftHanded')} />
                </FormGroup>
              </ListItem>
              <InputLabel>{t('specialMeal')}</InputLabel>
              <ListItem>
                <RadioGroup>
                  <FormControlLabel control={<Radio />} label={t('none')} onClick={() => setIsSpecialMealDisabled(true)}
                    value="none"
                  />
                  <FormControlLabel control={<Radio />} label={
                    <TextField fullWidth variant="outlined" placeholder={t('specialMealDescription')} size="small"
                      name="specialMeal" value={formik.values.specialMeal} onChange={formik.handleChange}
                      disabled={isSpecialMealDisabled}
                    />
                  }
                    value="specialMeal"
                    onClick={() => setIsSpecialMealDisabled(false)} />
                </RadioGroup>
              </ListItem>
              <FormHelperText sx={(theme) => ({ whiteSpace: 'pre-wrap', textAlign: 'justify', wordBreak: 'keep-all', [theme.breakpoints.down('sm')]: { fontSize: i18n.language === 'en' ? '0.65rem' : undefined } })}>{t('additional').replace(/^ +/gm, '')}</FormHelperText>
            </List>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Button color="primary" type="submit" variant="contained">
                {t("submit")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </div>
    </form>
  );
}
